import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { StandardLogService } from "src/standard/logging/logging";
import { EntityManagerProviderService } from "../../services//entity-manager-provider/entity-manager-provider.service";
import { UnitOfWorkService } from "../../services/unit-of-work/unit-of-work.service";
import { RequireAuthenticatedUserGuard } from "../auth/require-authenticated-user.guard";

@Injectable({
  providedIn: "root",
})
export class BreezeSetupGuard implements CanActivate {
  constructor(
    private entityManagerProvider: EntityManagerProviderService,
    private uowService: UnitOfWorkService,
    private authGuard: RequireAuthenticatedUserGuard,
    private log: StandardLogService,
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    const isAuth = await this.authGuard.canActivate(next, state);

    if (!isAuth) {
      return Promise.resolve(false);
    }

    return this.entityManagerProvider
      .init()
      .then(() => {
        this.uowService.init();
        return true;
      })
      .catch((e: unknown) => {
        this.log.logException(e);

        // Reload the page to try to fix itself
        // setTimeout(() => {
        //   localStorage.clear();
        //   sessionStorage.clear();
        //   window.location.reload();
        // }, 8_000);

        return false;
      });
  }
}
