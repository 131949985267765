import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { ServerSideConfigDataService } from "src/app/services/server-side-config-data/server-side-config-data.service";
import invariant from "tiny-invariant";
import { BreezeSetupGuard } from "../breeze-setup/breeze-setup.guard";

export function getResolvedUrl(route: ActivatedRouteSnapshot): string {
  return route.pathFromRoot
    .map((v) => v.url.map((segment) => segment.toString()).join("/"))
    .join("/");
}

@Injectable({
  providedIn: "root",
})
export class AppGuard implements CanActivate {
  constructor(
    private router: Router,
    private serverSide: ServerSideConfigDataService,
    private msalGuard: MsalGuard,
    private breezeGuard: BreezeSetupGuard,
  ) {}

  public async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    const resolvedUrl = getResolvedUrl(next);

    if (
      (await this.msalGuard
        .canActivate(next, state)
        .toPromise()
        .catch(() => false)) === false
    ) {
      return false;
    }

    const [breezeRes] = await Promise.all([
      this.breezeGuard.canActivate(next, state).catch(() => false),
      this.serverSide.loadServerSideData(),
    ]);

    if (breezeRes === false) {
      return false;
    }

    // Get License Model
    const licenseModel = window.__licenseModel;
    invariant(licenseModel, "License Model not found");

    // Check if Hosts match or is *
    const hostName = window.location.hostname;
    const hostNameCheck = licenseModel.hostName?.toLocaleLowerCase();
    const todaysDate = new Date();

    // Hoste not allowed for license
    if (licenseModel.hostName !== "*" && !hostNameCheck?.includes(hostName)) {
      if (
        resolvedUrl !== "/license-expired" &&
        state.url !== "/license-expired"
      ) {
        return this.router.parseUrl("/license-expired");
      }

      return true;
    }

    // License for site expired date
    if (
      licenseModel.expirationDate !== null &&
      todaysDate > new Date(licenseModel.expirationDate)
    ) {
      if (
        resolvedUrl !== "/license-expired" &&
        state.url !== "/license-expired"
      ) {
        return this.router.parseUrl("/license-expired");
      }

      return true;
    }

    if (window.__user && !window.__user.isValidUser) {
      if (resolvedUrl !== "/access-denied" && state.url !== "/access-denied") {
        return this.router.parseUrl("/access-denied");
      }

      return true;
    }

    return true;
  }
}
